// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "../node_modules/bs-platform/lib/es6/array.js";
import * as JsqrEs6 from "jsqr-es6";
import * as Pervasives from "../node_modules/bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "../node_modules/bs-platform/lib/es6/caml_option.js";

function getPointAsFloats(p) {
  return /* tuple */[
          p.x,
          p.y
        ];
}

function getMinAndMax(ary) {
  var currentMin = {
    contents: Pervasives.infinity
  };
  var currentMax = {
    contents: Pervasives.neg_infinity
  };
  $$Array.iter((function (x) {
          if (x < currentMin.contents) {
            currentMin.contents = x;
          }
          if (x > currentMax.contents) {
            currentMax.contents = x;
            return /* () */0;
          } else {
            return 0;
          }
        }), ary);
  return /* tuple */[
          currentMin.contents,
          currentMax.contents
        ];
}

function extractAABB(loc) {
  var match = getPointAsFloats(loc.topLeftCorner);
  var match$1 = getPointAsFloats(loc.topRightCorner);
  var match$2 = getPointAsFloats(loc.bottomRightCorner);
  var match$3 = getPointAsFloats(loc.bottomLeftCorner);
  var match$4 = getMinAndMax(/* array */[
        match[0],
        match$1[0],
        match$2[0],
        match$3[0]
      ]);
  var minX = match$4[0];
  var match$5 = getMinAndMax(/* array */[
        match[1],
        match$1[1],
        match$2[1],
        match$3[1]
      ]);
  var minY = match$5[0];
  return {
          x: minX | 0,
          y: minY | 0,
          w: Math.ceil(match$4[1] - minX) | 0,
          h: Math.ceil(match$5[1] - minY) | 0
        };
}

function string_of_invertOptions(param) {
  switch (param) {
    case /* AttemptBoth */0 :
        return "attemptBoth";
    case /* DontInvert */1 :
        return "dontInvert";
    case /* OnlyInvert */2 :
        return "onlyInvert";
    case /* InvertFirst */3 :
        return "invertFirst";
    
  }
}

function jsQR(d, w, h, invertOptions) {
  var optString = string_of_invertOptions(invertOptions);
  return Caml_option.nullable_to_opt(JsqrEs6.default(d, w, h, {
                  inversionAttempts: optString,
                  canOverwriteImage: true
                }));
}

export {
  getPointAsFloats ,
  getMinAndMax ,
  extractAABB ,
  string_of_invertOptions ,
  jsQR ,
  
}
/* jsqr-es6 Not a pure module */
